<template>
  <div>
    <van-form @submit="onSubmit">
      <van-field v-model="form.out_warehousing_to_name" is-link readonly name="out_warehousing_to_name" label="收货人" placeholder="请选择收货人"  required :rules="[{ required: true, message: '请选择收货人' }]" @click="storeShow = true" />
      <van-field v-model="form.warehouse_name" is-link  readonly name="warehouse_name" label="出库仓库" placeholder="请选择出库仓库" required :rules="[{ required: true, message: '请选择入库仓库' }]" @click="warehouseShow = true" />
      <van-field name="use_product_batch" label="生产批次入库">
        <template #input>
          <van-switch active-color='#5B9DFF' v-model="form.use_product_batch" size="20" />
        </template>
      </van-field>
      <van-field v-model="form.product_batch_name" is-link readonly name="product_batch_name" label="生产批次" placeholder="请选择生产批次" @click="batchShow = true" required :rules="[{ required: true, message: '请选择生产批次' }]" v-if='form.use_product_batch'/>
      <van-field v-model="form.product_name" is-link readonly name="product_name" label="商品名称" placeholder="请选择商品" required :rules="[{ required: true, message: '请选择商品' }]" @click="goodsShow = true" />
      <van-field name="use_product_batch" label="物流配送">
        <template #input>
          <van-switch @change="form.delivery_type = showType ? 1 : 2" active-color='#5B9DFF' v-model="showType" size="20" />
        </template>
      </van-field>
      <van-field v-model="form.logistics_name" is-link readonly name="logistics_name" label="物流公司" placeholder="请选择物流公司" @click="logisticsShow = true" v-if='form.delivery_type == 1' />
      <van-field v-model="form.logistics_code" name="logistics_code" label="物流单号" required :rules="[{ required: true, message: '请输入物流单号' }]" placeholder="请输入物流单号" v-if='form.delivery_type == 1' />
      <div class="tw-fixed tw-left-0 tw-bottom-0 s_flex_center width100 p-15">
        <van-button block round color="linear-gradient(to right, #53A1FF, #68D0FF)" native-type="submit">快速出库</van-button>
      </div>
    </van-form>
    <!-- 选择仓库 -->
    <van-popup v-model:show="warehouseShow" position="bottom" round safe-area-inset-bottom>
      <selectWarehouse :showChild='2' @cancel='warehouseShow = false' @confirm='warehouseConfirm'></selectWarehouse>
    </van-popup>
    <!-- 选择商品 -->
    <van-popup v-model:show="goodsShow" position="bottom" round safe-area-inset-bottom closeable :style="{ maxHeight: '80%'}">
      <selectGoods @select='goodsConfirm'></selectGoods>
    </van-popup>
    <!-- 选择批次 -->
    <van-popup v-model:show="batchShow" position="bottom" round safe-area-inset-bottom closeable :style="{ maxHeight: '80%'}">
      <selectGoodBatch @select='batchConfirm'></selectGoodBatch>
    </van-popup>
    <!-- 选择物流公司 -->
    <van-popup v-model:show="logisticsShow" position="bottom" round safe-area-inset-bottom :style="{ maxHeight: '80%'}">
      <selectLogistics @cancel='logisticsShow = false' @confirm='logisticsConfirm' ></selectLogistics>
    </van-popup>
    <!-- 选择收货人 -->
    <van-popup v-model:show="storeShow" position="bottom" round safe-area-inset-bottom closeable :style="{ maxHeight: '80%'}">
      <selectStore @select='storeConfirm'></selectStore>
    </van-popup>
    <audioPlay ref="audios" />
  </div>
</template>

<script>
  import { ref, onMounted, inject, reactive, toRefs } from "vue";
  import { Toast,Dialog } from 'vant';
  import { iscode } from '@/utils/app.js';
  import { useWare } from '@/hooks/function'
	import { setOutWarehouseKs,checkIncomplete,clearIncomplete } from '@/api/outWarehouse.js';
  let defForm = {
    out_warehousing_type: 1,
    out_warehousing_to_id: null,
    warehouse_id: null,
    warehouse_name:"默认仓库",
    use_product_batch:0,
    product_batch_id:'',
    product_id:'',
    product_sku_sn:'',
    delivery_type:2,
    logistics_name:'',
    logistics_code:''
  };
  export default {
    setup() {
      const form = ref({ ...defForm });
      const state = reactive({
        list:[{},{},{}],
        warehouseShow:false, //是否显示仓库选择
        goodsShow:false, //是否显示商品选择
        batchShow:false, //是否显示批次选择
        showType:false, //物流配送开关
        logisticsShow:false, //是否显示物流公司选择
        storeShow:false,//是否显示收货人选择
      })
      const $route = inject("$route");
      const $router = inject("$router");
      // 仓库选择
      const warehouseConfirm = (e) =>{
        form.value.warehouse_id = e.id;
        form.value.warehouse_name = e.name
        state.warehouseShow = false
      }
      // 商品选择
      const goodsConfirm = (e) =>{
        form.value.product_id = e.id;
        form.value.product_name = (e.product_sku_name?e.product_sku_name:'单规格')+' - '+e.product_name;
        form.value.product_sku_sn = e.product_sku_sn || 0;
        state.goodsShow = false
      }
      // 批次选择
      const batchConfirm = (e) =>{
        form.value.product_id = e.product_id;
        form.value.product_name = e.product_name;
        form.value.product_sku_sn = e.product_sku_sn || 0;
        form.value.product_batch_id = e.id;
        form.value.product_batch_name = e.batch_name;
        state.batchShow = false
      }
      // 物流选择
      const logisticsConfirm = (e) =>{
        form.value.logistics_name = e.name
        state.logisticsShow = false
      }
      // 收货人选择
      const storeConfirm = (e) =>{
        form.value.out_warehousing_to_id = e.id
        form.value.out_warehousing_to_name = e.name
        state.storeShow = false
      }
      const audios=ref('')
      const onSubmit = async(values) => {
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
          duration:0
        });
        let result = await setOutWarehouseKs({ ...form.value}).then(res => res.data).catch(error => error)
        Toast.clear();
        if (iscode(result)) {
          $router.push('/outWarehouse/byFastCode?sn='+result.data.out_warehousing_sn)
				}else{
          Toast.fail(result.msg);
          audios.value.error()
				}
      };
      // 检测上一次是否完成
      const checkOutStatus=async ()=>{
        let result=await checkIncomplete().then(res=>res.data).catch(error=>error)
        console.log(result)
        if(iscode(result)){
          if(result.data.order_no){
            Dialog.confirm({
              title: '温馨提示',
              cancelButtonText:'删除',
              message: `您上次有未完成单号${result.data.order_no},是否继续？`,
            })
              .then(() => {
                 $router.push('/outWarehouse/byFastCode?sn='+result.data.order_no)
              })
              .catch(() => {
                // on cancel
                clearIncomplete(result.data.order_no)
              });
          }
        }
      }
      onMounted(async () => {
        checkOutStatus()
       let ware= await useWare().getWareList()
        form.value.warehouse_id = ware.data[0].id;
        form.value.position_id = 0;
        form.value.warehouse_name = ware.data[0].name
      })

      return {
        ...toRefs(state),
        form,
        audios,
        onSubmit,
        warehouseConfirm,
        goodsConfirm,
        batchConfirm,
        logisticsConfirm,
        storeConfirm
      };
    }
  };
</script>
